// whs global js script
if (typeof WWW_ROOT === "undefined") {
    var WWW_ROOT = "/";
}

window.overlayPallet = function (pallet_id) {
    if (pallet_id && pallet_id > 1000000000) {
        $("#palletOverlay").dialog({
            draggable: false,
            modal: true,
            open: function () {
                $(this).html(
                    '<center class="flex items-center justify-center h-full"><img src="' + WWW_ROOT +
                    'skin/images/loading-pallet.gif" title="Loading" alt="Loading" style="width: 40%" /><center>'
                );
                $(this).load(WWW_ROOT + "pallet/overlay/id/" + pallet_id);
            },
            height: 400,
            width: 600,
            title: "Pallet " + pallet_id,
        });
    }

    return false;
};

window.palletDetail = function (val) {
    var arr = val.split("=");
    var pallet_id = arr[1]; //jQuery("#list").jqGrid('getCell', arr[1],'pallet_id' );
    overlayPallet(pallet_id);
}

window.changeLocation = function (locName, pallet_id) {
    if (locName && pallet_id) {
        $.post(
            WWW_ROOT + "pallet/changelocation",
            {
                pallet_id: pallet_id,
                location: locName,
            },
            function (data) {
                //console.log(data);
                var res = JSON.parse(data);
                console.log(res);
                if (res.status == 1) {
                    alert("Location has been updated");
                } else {
                    alert("This location could not be changed");
                }
            }
        );
    }
}

window.softDeletePallet = function (pallet_id) {
    if (confirm("Are You sure to delete this pallet(" + pallet_id + ")")) {
        if (pallet_id) {
            $.post(
                WWW_ROOT + "goduser/deletepallet",
                {
                    pallet_id: pallet_id,
                    ajax: 1,
                },
                function (data) {
                    console.log(data);
                }
            );

            $.ajax({
                url: WWW_ROOT + "goduser/deletepallet",
                data: {
                    pallet_id: pallet_id,
                    ajax: 1,
                },
                error: function (xhr) {
                    var res = $.parseJSON(xhr.responseText);
                    alert(res.message);
                },
            });
        }
    }
}

window.pendingDeletePallet = function (pallet_id) {
    if (confirm("Are You sure to delete this pallet(" + pallet_id + ")")) {
        if (pallet_id) {
            $.post(
                WWW_ROOT + "pending/deletepallet",
                {
                    pallet_id: pallet_id,
                    ajax: 1,
                },
                function (data) {
                    console.log(data);
                }
            );
        }
    }
}

window.exportExcel = function () {
    var mya = new Array();
    mya = $("#list").getDataIDs(); // Get All IDs
    var data = $("#list").getRowData(mya[0]); // Get First row to get the labels
    var colNames = new Array();
    var ii = 0;
    for (var i in data) {
        colNames[ii++] = i;
    } // capture col names
    var html = "";

    let column_headers = $("#list").jqGrid("getGridParam", "colNames");
    let skipped = false;

    column_headers.forEach(function (name) {
        if (!skipped) {
            skipped = true;
            return;
        };

        html += name + "\t";
    });

    html += "\n";

    for (i = 0; i < mya.length; i++) {
        data = $("#list").getRowData(mya[i]); // get each row
        for (j = 0; j < colNames.length; j++) {
            html = html + data[colNames[j]] + "\t"; // output each column as tab delimited
        }
        html = html + "\n"; // output each row with end of line
    }

    html = html + "\n";

    var form = document.createElement("form");
    form.id = "form-csv-export";
    form.action = "/export/csv";
    form.method = "post";
    form.target = "_blank";

    var hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.id = "csvBuffer";
    hiddenInput.name = "csvBuffer";
    hiddenInput.value = html;

    var submitButton = document.createElement("input");
    submitButton.type = "submit";

    form.appendChild(hiddenInput);
    document.body.appendChild(form);
    form.submit();

    // document.forms[0].submit();
    setTimeout(function () {
        form.parentNode.removeChild(form);
    }, 5e3);
}

window.getCellValue = (rowId, cellId) => {
    var val = jQuery("#list").jqGrid("getCell", rowId, cellId);
    //var val = cell.val();
    return val;
};

window.wire_resend_button = () => {
    let $grid = $("#list");
    let $resend_button = $("#resend_subs_button");

    let $print_button = $("#print_button");
    let id_array = $grid.jqGrid("getGridParam", "selarrrow");

    $grid
        .on("jqGridSelectRow jqGridSelectAll", function () {
            id_array = $grid.jqGrid("getGridParam", "selarrrow");

            if (($resend_button.length || $print_button.length) && id_array.length) {
                $resend_button.prop("disabled", false);
                $print_button.prop("disabled", false);

                $resend_button.show();
                return;
            }

            $resend_button.prop("disabled", true);
            $print_button.prop("disabled", true);

            $resend_button.hide();
        })
        .on("jqGridShowHideCol", function (ev) {
            let current_grid_width = $(ev.target).width(),
                minimum_grid_width = $("#right-section").width();

            if (current_grid_width < minimum_grid_width) {
                current_grid_width = minimum_grid_width;
            }

            $(ev.target).jqGrid("setGridWidth", current_grid_width);
        });

    $resend_button.off("click").on("click", function () {
        $resend_button.prop("disabled", true);
        $.post(
            "/subscribe/resend",
            {
                id_array: id_array,
                sub_type: $resend_button.attr("data-type"),
            },
            function () {
                $grid.jqGrid("resetSelection");
                $resend_button.prop("disabled", false);
                id_array = $grid.jqGrid("getGridParam", "selarrrow");

                alert("Selected orders successfully queued for resend process");
            }
        );
    });
};

window.wire_flag_button = function () {
    let $flag_button = $("#flag_order_btn");
    let $grid = $("#list");

    let id_array = $grid.jqGrid("getGridParam", "selarrrow");

    $grid.on("jqGridSelectRow jqGridSelectAll", function () {
        $flag_button.hide();

        if (id_array.length) {
            $flag_button.show();
        }
    });

    $flag_button.on("click", function () {
        $.post(
            "/ajax_inward/flag-orders",
            {
                orders: id_array,
            },
            function () {
                window.location.reload();
            }
        );
    });
}

window.setCookie = function (name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}
window.getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
window.eraseCookie = function (name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

window.convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
        hours = "00";
    }

    if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
};

window.convertTime24to12 = (time) => {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time,
    ];

    const padStart = (n, width, z) => {
        z = z || "0";
        n = n + "";
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? " AM" : " PM";
        time[0] = +time[0] % 12 || 12;
    }

    time[0] = padStart(time[0], 2, 0);
    return time.join("");
};

window.createSelect = function (data) {
    return data;
};

(function ($) {
    // whs global object
    $.whs = $.whs || {};

    // keep desktop user session alive
    setInterval(function () {
        $.ajax({ url: "/index/session", type: "POST", async: true });
    }, 15e3);

    // prevent double submit on buttons
    $(document).on("click", "input[type=submit]", function (e) {
        var $this = $(e.target);
        setTimeout(function () {
            $this.prop("disabled", true);
        }, 500);

        setTimeout(function () {
            $this.prop("disabled", false);
        }, 3e3);
    });

    // remember the filter settings in grids
    $(window).on("load", function () {
        let reloaded = false;
        let $grid_element = $("table.ui-jqgrid-btable");
        let grid_filter_id = window.location.pathname
            .substring(1)
            .replaceAll("/", "_");
        let quick_filter_id = "current_filters_" + grid_filter_id;

        $grid_element.on("jqGridBeforeRequest", function () {
            store.session.add(
                grid_filter_id,
                $grid_element.jqGrid("getGridParam", "postData")
            );
        });

        $grid_element.on("jqGridGridComplete", function () {
            let stored_postData = store.session.get(grid_filter_id);
            if (
                !reloaded &&
                stored_postData &&
                stored_postData.hasOwnProperty("filters") &&
                stored_postData.filters
            ) {
                $grid_element.jqGrid("setGridParam", { postData: stored_postData });
                reloaded = true;
            }

            if (
                $.inArray(window.location.pathname.substring(1), [
                    "inward",
                    "outward",
                ]) !== -1
            ) {
                $.post("/index/session", { grid_filter_id, filters: stored_postData });
            }

            // $('#right-section').css('overflow-y', 'hidden');

            $.whs.refreshGridFilterOptions();
            reloaded = false;
        });

        // order filters
        $("#quick-filter-toggle").on("click", function (e) {
            e.preventDefault();

            $("#filter-options").toggleClass("hide show");
            $("#quick-filter-toggle .fa").toggleClass(
                "fa-chevron-right fa-chevron-down"
            );
        });

        $.whs.refreshGridFilterOptions = function () {
            let stored_quick_filters = store.session.get(quick_filter_id);

            if (stored_quick_filters) {
                $.map(stored_quick_filters, function (val) {
                    let $filter = JSON.stringify(val);
                    let current_filter_el = $(".filter-option-list a[data-data='" + $filter + "']");

                    current_filter_el.addClass("current-filter");

                    $("#quick-filter-toggle").addClass("is-filtered");
                    $("#quick-filter-toggle .icon-label").empty().text("Filtered");

                    // its custom filter
                    if (current_filter_el.length === 0) {
                        $("#filter-custom-date")
                            .attr("data-data", JSON.stringify(stored_quick_filters))
                            .addClass("current-filter")
                            .find("#filter-custom-date-text")
                            .text(stored_quick_filters[0].eq);
                    }

                    if (!reloaded) {
                        setTimeout(setQuickFilters, 500);
                    }
                });
            } else {
                $(`.filter-option-list a`).removeClass("current-filter");
                $("#quick-filter-toggle").removeClass("is-filtered");
                $("#quick-filter-toggle .icon-label").empty().text("Order Filters");
            }
        };

        let picker = $(".filter-custom-date-input").datepicker({
            dateFormat: "dd-M-yy",
            onSelect: function (dateText, obj) {
                $(".ui-datepicker").hide();

                $("#filter-custom-date-text").text(dateText);
                $("#filter-custom-date")
                    .addClass("current-filter")
                    .data("data", { eq: dateText })
                    .attr("data-data", JSON.stringify({ eq: dateText }));

                setQuickFilters();
            },
        });

        function setQuickFilters() {
            let filters = { groupOp: "AND", rules: [] },
                $quick_filter_toggle = $("#quick-filter-toggle"),
                $quick_filter_toggle_label = $quick_filter_toggle.find(".icon-label"),
                current_filters = [];

            $(document)
                .find(".filter-options-container a.current-filter")
                .each(function (index, el) {
                    let el_data = $(el).data("data"),
                        field = $(el).data("field");

                    if (el_data) {
                        $.map(el_data, function (data, op) {
                            filters.rules.push({ op, data, field });
                        });
                    }

                    current_filters.push(el_data);
                });

            store.session.remove(quick_filter_id);
            store.session.add(quick_filter_id, current_filters);

            let postData = { filters: JSON.stringify(filters) };
            $grid_element.jqGrid("setGridParam", { postData }).trigger("reloadGrid");

            $quick_filter_toggle_label.text("Order Filters");
            $quick_filter_toggle.removeClass("is-filtered");

            if (current_filters.length) {
                $quick_filter_toggle.addClass("is-filtered");
                $quick_filter_toggle_label.text("Filtered");
            }
        }

        $(document).on("click", ".filter-option-block a", function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();
            let $this = $(e.currentTarget);

            $this
                .parents(".filter-option-list")
                .find("a")
                .removeClass("current-filter");
            $this.addClass("current-filter");
            if (
                $("#filter-custom-date", $this).length ||
                $("#filter-custom-date", $this.parent()).length
            ) {
                picker.datepicker("show");
                return;
            }

            setQuickFilters();
        });

        $(document).on("click", ".filter-option-block .close-icon", function (e) {
            e.stopImmediatePropagation();
            e.preventDefault();

            let $this = $(e.target);
            $this.parent().removeClass("current-filter");
            $("#filter-custom-date-text")
                .text("Set Custom Date")
                .removeClass("current-filter");
            $("#filter-custom-date").removeAttr("data-data").removeData("data");

            setQuickFilters();
        });

        // bulk update as 'Ready To Pick'
        $.whs.setReadyPickTodo = 0;
        $.whs.setReadyPickSelectedOrders = [];
        $.whs.setReadyPickButton = $("#button_ready_pick");
        $.whs.setReadyForDespatch = $("#button_ready_for_despatch");

        $.whs.setReadyPickInit = function (args) {
            $.whs.setReadyPickSelectedOrders = (
                args ?? $grid_element.jqGrid("getGridParam", "selarrrow")
            ).filter(function (rowID) {
                let { "Order Status": status } = (rowData = $grid_element.jqGrid(
                    "getRowData",
                    rowID
                ));
                let criteria = status ?? rowData.status;
                return $.inArray(criteria, ["Ready To Process"]) !== -1;
            });
        };

        $.whs.setReadyForDespatch.on("click", function () {
            let selectedOrders = $grid_element.jqGrid("getGridParam", "selarrrow");

            deliveredOrders = selectedOrders.filter(function (rowId) {
                let { "Order Status": status } = $grid_element.jqGrid(
                    "getRowData",
                    rowId
                );
                return status === "Delivered";
            });

            deliveredOrders.forEach(function (order_id) {
                let data = { order_id, status: "Ready For Despatch" };
                $.post("/outward/changeoutwardstatus", data, function () {
                    $grid_element.trigger("reloadGrid");
                });
            });
        });

        $.whs.setReadyPickButton.on("click", function (e) {
            e.preventDefault();
            if ($.whs.setReadyPickSelectedOrders.length === 0) {
                alert("Nothing to update");
                return;
            }

            textProgress(0).prop("disabled", true).css({
                cursor: "not-allowed",
                background: "gray",
            });

            setOrderStatus($.whs.setReadyPickSelectedOrders).then(function () {
                $grid_element.trigger("reloadGrid");
                $.whs.setReadyPickButton
                    .delay(1e3)
                    .text("Done")
                    .delay(1e3)
                    .text("Bulk Ready To Pick")
                    .css({
                        background: "orange",
                        cursor: "pointer",
                    })
                    .prop("disabled", false)
                    .val("Bulk Ready To Pick");

                $.whs.setReadyPickTodo = 0;
            });
        });

        function textProgress(count_todo) {
            let display_text = `Updating (${count_todo}/${$.whs.setReadyPickSelectedOrders.length})`;
            return $.whs.setReadyPickButton.text(display_text).val(display_text);
        }

        async function setOrderStatus(orders) {
            await Promise.all(
                orders.map(async function (id) {
                    let data = { order_id: id, status: "Ready To Pick" };
                    await $.post("/outward/changeoutwardstatus", data, function () {
                        $.whs.setReadyPickTodo++;
                        textProgress($.whs.setReadyPickTodo);
                    });
                })
            );
        }
    });
})(jQuery);
