// bootstrap all global dependencies
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'material-icons/iconfont/material-icons.css'

// consolidate all existing css files
import '~/skin/css/ui.jqgrid.css'
import '~/skin/selectize/css/selectize.bootstrap3.css'
import '~/skin/selectize/css/selectize.css'

import 'jquery'
import 'jquery-form'
import 'webpack-jquery-ui'
import 'webpack-jquery-ui/css'
import '@selectize/selectize'

import 'preline/dist/preline.js'
import DataTable from 'datatables.net-dt'
window.DataTable = DataTable

import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en.js'
window.AirDatepicker = AirDatepicker
window.localeEn = localeEn

// jqgrid ui
import '~/skin/js/jquery.jqGrid.min'
import '~/skin/js/i18n/grid.locale-en'
import '~/skin/js/grid.setcolumns'

// used in remembering last set grid settings
// which are typically in goods in and goods out summary pages
import * as store from '~/skin/js/store.min'
window.store = store

// whs
import '~/skin/js/whs'

// fancybox
import * as Fancy from 'fancygrid'
window.Fancy = Fancy

// execute scripts...
// after window 'load'
$(window).on('load', () => {
    // global search box
    $('#global-search-box').autocomplete({
        source: '/ajax/searchsuggest',
        minLength: 2,
        select: (event, ui) => {
            const { redirect_url } = ui.item
            window.location = redirect_url
        }
    })

        // customize autocomplete results
        .autocomplete('instance')._renderItem = (ul, item) => {
            ul.css({ 'max-height': 200, 'max-width': 240 })
                .addClass('overflow-y-auto overflow-x-hidden')

            let result = $('<div />')
                .addClass('p-2 text-sm font-semibold border border-solid border-transparent')
                .append(item.label)

            return $('<li />').addClass('border border-solid border-b border-slate-200')
                .append(result)
                .appendTo(ul)
        }

    // edi count badge
    let ediCounter;
    (ediCounter = function () {
        $.get('/ajax/edi-count', (response) => {
            const { count } = response
            const badgeId = '#edi-count-badge'

            if (parseInt(count)) {
                return $(badgeId).text(count).fadeIn()
            }

            $(badgeId).text(0).fadeOut()
        }).done(() => setTimeout(ediCounter, 10e3))
    })()

    // tooltips plugin
    tippy('.with-tooltips', {
        allowHTML: true,
        content(ref) {
            let id = $(ref).data('template');
            return $(id).html();
        },
        placement: 'bottom',
    });

})
